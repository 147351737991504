import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p className="text-center mx-auto">© 2023 - SH Food Stuff</p>
    </div>
  );
};
export default Footer;
